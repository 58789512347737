// 仓库管理列表表头
export const depotColumns = () => {
  return [
    {
      title: '序号',
      width: 70,
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '仓库名称',
      width: 200,
      align: 'left',
      scopedSlots: { customRender: 'name' }
    },
    {
      title: '所属部门',
      width: 250,
      align: 'left',
      dataIndex: 'department.name'
    },
    {
      title: '状态',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'status' }
    },
    {
      title: '所在位置',
      width: 200,
      align: 'left',
      dataIndex: 'detailLocation',
      scopedSlots: { customRender: 'detailLocation' }
    },
    {
      title: '操作',
      width: 200,
      align: 'center',
      scopedSlots: { customRender: 'action' }
    }
  ]
}

// 仓库状态
export const statusList = function () {
  return [
    { value: 'START', label: '启用' },
    { value: 'STOP', label: '停用' }
  ]
}